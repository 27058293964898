$(function () {
  $("#signinBtn").on("click", access);
  $("#password").on("keydown", function (key) {
    if (key.keyCode === 13) access();
  });
});

function access() {
  const email = $("#email").val();
  const password = $("#password").val();

  if (email == "") {
    alert("이메일을 입력해주세요");
    return;
  }

  if (password == "") {
    alert("비밀번호를 입력해주세요");
    return;
  }

  $.ajax({
    url: "/signin",
    dataType: "json",
    type: "POST",
    data: { "email": email, "password": password },
    error: function (xhr, status, error) {
      alert("로그인 할 수 없습니다");
    },
    success: function (data) {
      location.href = "/database/chatbot";
    }
  });
}